import React from 'react'

import css from './ShareButton.module.scss'

const ShareButton = (props) => (
  <a className={`${css.ShareButton} ${css[props.color]}`} href={props.to}>
    <span>
      <i className={props.icon}></i>
    </span>
    {props.text}
  </a>
)

export default ShareButton
