import { contactConstants } from '../constants'

const initialState = {
  loading: false,
  message: null,
  clear: false
}

export default function course(state = initialState, action) {
  switch (action.type) {
    case contactConstants.CONTACT_START:
      return { ...state, loading: true }
    case contactConstants.CONTACT_SUCCESS:
      return { ...state, loading: false, message: action.payload, clear: true }
    case contactConstants.CONTACT_FAILURE:
      return { ...state, loading: false, message: action.payload }
    case contactConstants.CONTACT_CLEAR:
      return initialState
    default:
      return state
  }
}
