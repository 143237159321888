import React from 'react'

import css from './Organizations.module.scss'

import Federkravmaga from '../../../../../assets/organizations/federkravmaga.png'
import Endas from '../../../../../assets/organizations/endas.png'
import EndasSecurity from '../../../../../assets/organizations/endas_security.png'

const Organizations = () => (
  <div className={css.Partners}>
    <a
      href="https://www.federkravmaga.com/"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={Federkravmaga} alt="Federkravmaga" />
    </a>
    <a
      href="https://www.endas.it/"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={Endas} alt="Endas" />
    </a>
    <a
      href="https://www.endas.it/dipartimento-sicurezza/"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={EndasSecurity} alt="Endas Security" />
    </a>
  </div>
)

export default Organizations
