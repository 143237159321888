import { takeEvery } from 'redux-saga/effects'
import {
  announcementConstants,
  authConstants,
  contactConstants,
  courseConstants,
  feedbackConstants,
  instructorConstants
} from '../constants'
import { loadAnnouncementsSaga } from './announcement.sagas'
import { loadCoursesSaga, saveCourseSaga } from './course.sagas'
import { loadFeedbacksSaga } from './feedback.sagas'
import { loadInstructorsSaga } from './instructor.sagas'
import { addContactSaga } from './contact.sagas'
import { loginGoogleSaga } from './auth.sagas'

export function* watchAnnouncement() {
  yield takeEvery(
    announcementConstants.ANNOUNCEMENT_INIT,
    loadAnnouncementsSaga
  )
}

export function* watchCourse() {
  yield takeEvery(courseConstants.COURSE_INIT, loadCoursesSaga)
  yield takeEvery(courseConstants.COURSE_SAVE, saveCourseSaga)
}

export function* watchInstructor() {
  yield takeEvery(instructorConstants.INSTRUCTOR_INIT, loadInstructorsSaga)
}

export function* watchFeedback() {
  yield takeEvery(feedbackConstants.FEEDBACK_INIT, loadFeedbacksSaga)
}

export function* watchContact() {
  yield takeEvery(contactConstants.CONTACT_START, addContactSaga)
}

export function* watchAuth() {
  yield takeEvery(authConstants.AUTH_LOGIN_GOOGLE, loginGoogleSaga)
}
