import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import css from './ContactForm.module.scss'

import { requestContact } from '../../../redux/actions'
import ShareButton from '../ShareButton/ShareButton'
import { appearanceConstants } from '../../../constants'

const ContactForm = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [question, setQuestion] = useState(
    appearanceConstants.contact.questions[0].value
  )

  const submitFormHandle = () => {
    props.requestContact({ name, email, phone, message, question })
  }

  const submitIsDisabled =
    name === '' || email === '' || message === '' || props.contact.loading

  useEffect(() => {
    if (props.contact.clear) {
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
      setQuestion(appearanceConstants.contact.questions[0].value)
    }
  }, [props.contact.clear])

  return (
    <div className={css.ContactForm}>
      <ul className={css.UlForm}>
        <li>
          <h1>contattaci</h1>
          Siamo disponibili per chiarire qualsiasi tuo dubbio, necessità o
          richiesta particolare.
          <h2 className="mt">Preferisci un contatto diretto?</h2>
          <ul className={css.UlShare}>
            <li>
              <ShareButton
                to={'https://wa.me/+393337248456'}
                text={'Whatsapp'}
                icon={'fab fa-whatsapp'}
                color={'whatsapp'}
              />
            </li>
            <li>
              <ShareButton
                to={'tel:+393337248456'}
                text={'Telefono'}
                icon={'fas fa-phone'}
                color={'phone'}
              />
            </li>
            <li>
              <ShareButton
                to={'mailto:info@homedefense.it'}
                text={'Email'}
                icon={'far fa-envelope'}
                color={'email'}
              />
            </li>
          </ul>
        </li>
        <li>
          <label>
            Tipo richiesta
            <select
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            >
              {appearanceConstants.contact.questions.map((q, key) => (
                <option key={key} value={q.value}>
                  {q.label}
                </option>
              ))}
            </select>
          </label>
          <label>
            Nome Cognome *
            <input
              type="text"
              placeholder="Nome Cognome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label>
            Email *
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label>
            Telefono
            <input
              type="phone"
              placeholder="Telefono"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
          <label>
            Messaggio *
            <textarea
              placeholder="Messaggio"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </label>
          <span className={css.Privacy}>
            Inviando questo messaggio accetti la nostra{' '}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
          <div className={css.SubmitContainer}>
            <button
              disabled={submitIsDisabled}
              className={css.SubmitForm}
              onClick={submitFormHandle}
            >
              {props.contact.loading
                ? 'invio in corso'
                : // <i className="fas fa-sync fa-spin"></i>
                  'invia richiesta'}
            </button>
            <span className={css.Error}>{props.contact.message}</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { requestContact })(ContactForm)
