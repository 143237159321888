import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { announcementsRef } from '../../database'
import { reduxHelper } from '../../helpers/'
import { onSnapshot, query, where } from 'firebase/firestore'

import { updateAnnouncement, removeAnnouncement } from '../actions'

export let announcementsSnap

export function* loadAnnouncementsSaga() {
  try {
    const q = query(announcementsRef, where('show', '==', true))
    const listener = eventChannel((emit) => {
      announcementsSnap = onSnapshot(q, (result) => {
        result.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => announcementsRef.off(listener)
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateAnnouncement({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateAnnouncement({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeAnnouncement({ id, ...data }))
      }
    }
  } catch (error) {
    reduxHelper.dispatchError(error)
  }
}
