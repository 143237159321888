import React from 'react'
import { connect } from 'react-redux'

import css from './Instructors.module.scss'

import Instructor from './Instructor/Instructor'
const Instructors = (props) => (
  <div className={css.Instructors}>
    {(props.instructor.list || []).map((instructor) => (
      <Instructor key={instructor.id} instructor={instructor} />
    ))}
  </div>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Instructors)
