import { uiConstants } from '../constants'

const initialState = {
  menuIsOpen: false
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case uiConstants.TOGGLE_MENU:
      return { ...state, menuIsOpen: !state.menuIsOpen }
    case uiConstants.CLOSE_MENU:
      return { ...state, menuIsOpen: false }
    default:
      return state
  }
}
