import React from 'react'

import css from './Registrations.module.scss'

const Registrations = React.memo(() => (
  <div className={css.Banner}>
    <div className={css.InsideBanner}>
      <div className={css.In}>
        <div className={css.Title}>Come iscriversi</div>
        Scivi una mail a{' '}
        <a href="mailto:info@homedefense.it">info@homedefense.it</a>, contattaci
        su whatsapp al <a href="https://wa.me/393337248456">333 7248 456</a>,
        oppure usa il form in fondo alla pagina.
      </div>
    </div>
  </div>
))

export default Registrations
