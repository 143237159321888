import { authConstants } from '../constants'

export const loginGoogle = () => {
  return {
    type: authConstants.AUTH_LOGIN_GOOGLE
  }
}

export const loginSuccess = (payload) => {
  return {
    type: authConstants.AUTH_LOGIN_SUCCESS,
    payload
  }
}

export const loginFailure = (payload) => {
  return {
    type: authConstants.AUTH_LOGIN_FAILURE,
    payload
  }
}
