import Lawenforcement from '../assets/covers/lawenforcement.jpg'
import Homedefense from '../assets/covers/homedefense.jpg'
import Pistol from '../assets/covers/pistol.jpg'
import Rifle from '../assets/covers/rifle.jpg'
import Police from '../assets/covers/police.jpg'
import Smart from '../assets/covers/smart.jpg'
import Simulation from '../assets/covers/simulation.jpg'

import KillHouse from '../assets/boxes/killhouse.jpg'
import Casabella from '../assets/boxes/casabella.jpg'
import Defendo from '../assets/boxes/defendo.jpg'

export const appearanceConstants = {
  dateFormat: 'DD MMM YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  hourFormat: 'HH:mm',
  dayOfWeek: '0',
  timeToDateInput: 'YYYY-MM-DD',
  menu: [
    { label: 'Home', to: '/' },
    {
      label: 'Difesa Abitativa',
      description: 'Home Defense',
      to: '/home-defense'
    },
    {
      label: 'Tiro Operativo Civili',
      description: 'Combat Shooting - Civil',
      to: '/combat-shooting-civil'
    },
    {
      label: 'Tiro Operativo GPG',
      description: 'Combat Shooting - Security',
      to: '/combat-shooting-security'
    },
    {
      label: "Tiro Operativo Forze dell'ordine",
      description: 'Combat Shooting - Police',
      to: '/combat-shooting-police'
    },
    {
      label: 'Simulation',
      description: 'Simulazioni di conflitti armati',
      to: '/simulation'
    },
    {
      label: 'Corsi/Eventi Speciali',
      description: 'Special events and courses',
      to: '/special-events-and-courses'
    },
    { label: 'Calendario corsi', to: '/calendar' },
    { label: 'Partners', to: '/partners' },
    { label: 'Safe Guarding e tutela minori', to: '/safe-guarding' }
  ],
  sliders: [
    {
      id: 'hd',
      image: Homedefense,
      top: 'Home Defense',
      middle: 'difesa abitativa',
      bottom: 'Impara a proteggere te stesso e la tua famiglia',
      to: '/home-defense'
    },
    {
      id: 'pistol',
      image: Pistol,
      top: 'Combat Shooting - Civil',
      middle: 'arma corta',
      bottom: 'Metodologie di tiro con arma corta',
      to: '/combat-shooting-civil'
    },
    {
      id: 'long',
      image: Rifle,
      top: 'Combat Shooting - Civil',
      middle: 'arma lunga, AR, AK, fucile a pompa',
      bottom: 'Metodologie di tiro con arma lunga',
      to: '/combat-shooting-civil'
    },
    {
      id: 'gpg',
      image: Lawenforcement,
      top: 'Combat Shooting - Security',
      middle: 'Guardie Giurate',
      bottom: 'Corsi specifici per Guardie Giurate',
      to: '/combat-shooting-security'
    },
    {
      id: 'police',
      image: Police,
      top: 'Combat Shooting - Police',
      middle: "Forze dell'ordine",
      bottom: "Corsi specifici per forze dell'ordine",
      to: '/combat-shooting-police'
    },
    {
      id: 'smart',
      image: Smart,
      top: 'Special events and courses',
      middle: 'Corsi ed eventi speciali',
      bottom: 'Corsi ed eventi speciali, kit di conversione, etc.',
      to: '/special-events-and-courses'
    },
    {
      id: 'simulation',
      image: Simulation,
      top: 'T4E Umarex',
      middle: 'Kill House',
      bottom: 'Poligono al chiuso per simulazioni di conflitti armati',
      to: '/simulation'
    }
  ],
  boxes: [
    {
      id: 'defendo',
      title: 'Defendo Krav maga',
      description:
        "Difesa personale con metodologia israeliana / krav maga, adatto per civili, forze dell'ordine e operatori della sicurezza.",
      to: '/partners',
      image: Defendo
    },
    {
      id: 'casabella',
      title: 'armeria casabella',
      description:
        'Armeria Casabella si occupa da oltre 60 anni di commercio di armi, munizioni, abbigliamento, ottiche e materiale per ricarica delle migliori marche, nazionali ed estere.',
      to: '/partners',
      image: Casabella
    },
    {
      id: 'killhouse',
      title: 'kill house',
      description:
        "Una Kill House è una struttura utilizzata per simulare gli scontri ravvicinati negli ambienti urbani. Situata a Usmate Velate, in provincia di Monza e Brianza, con oltre 350 mq di spazi e un ampio parcheggio, offre attività di formazione inerenti la difesa personale e abitativa, con l'ausilio delle armi T4E Umarex.",
      to: '/partners',
      image: KillHouse
    }
    // {
    //   id: 'tacticlub',
    //   title: 'Tacti.Club',
    //   description:
    //     'Entra in un club esclusivo! Potrai accedere a sconti su prodotti armeria Casabella, corsi Home Defense e Defendo Krav Maga. Join us, #jointacticlub',
    //   to: '/partners',
    //   image: KillHouse
    // }
  ],
  messages: {
    contactSuccess:
      'Richiesta inviata con successo, ti contatteremo il prima possibile.',
    contactFailure: 'Errore durante invio richiesta, riprovare, grazie.'
  },
  modules: [
    {
      description: 'Uso delle barriere e come muoversi in un corridoio.',
      color: 'red'
    },
    {
      description: 'Cambio caricatore: come farlo e quando farlo.',
      color: 'yellow'
    },
    {
      description:
        'Gestire gli inceppamenti: rispondere prontamente ad un problema può essere la differenza tra la vita e la morte.',
      color: 'green'
    },
    {
      description:
        'Bersagli mobili: ingaggiare un bersaglio che si muove è ben diverso che gestire una sagoma statica di cartone.',
      color: 'blue'
    },
    {
      description:
        'Tiro da sdraiato e in ginocchio, utilizzo delle protezioni basse.',
      color: 'orange'
    },
    {
      description:
        'Bersagli multipli ravvicinati: chi ingaggiare per primo? Perché?',
      color: 'purple'
    },
    {
      description:
        "Bonifica di una stanza: come preparare l'ingresso e come muoversi una volta entrati.",
      color: 'pink'
    },
    {
      description:
        'Uso tattico della torcia: è sempre preferibile accendere la luce, ma se costretti dobbiamo saper usare la torcia nel modo giusto.',
      color: 'brown'
    },
    {
      description: 'Tiro a una mano: forte e debole, gestire un arto ferito.',
      color: 'lime'
    },
    {
      description:
        'Bersagli mobili aggressivi: aggressore lanciato verso di te, ingaggio e spostamento.',
      color: 'cyan'
    },
    {
      description:
        'Stress: raggiungi e supera i tuoi limiti. Rivedi quanto appreso nei precedenti moduli ma con un grado di difficoltà maggiore.',
      color: 'blue-grey'
    }
  ],
  contact: {
    questions: [
      { value: 'general-information', label: 'Informazioni Generali' },
      { value: 'home-defense-course', label: 'Corso Home Defense' },
      {
        value: 'combat-shooting-civil-course',
        label: 'Corso Combat Shooting Civil'
      },
      {
        value: 'combat-shooting-security-course',
        label: 'Corso Combat Shooting Security'
      },
      {
        value: 'combat-shooting-police-course',
        label: 'Corso Combat Shooting Police'
      },
      { value: 'collaboration', label: 'Collaborazione' },
      { value: 'problem', label: 'Problema/Lamentela' }
    ]
  },
  courseTypes: [
    'home defense',
    'combat shooting civil',
    'combat shooting security',
    'combat shooting police',
    'special events and courses'
  ],
  social: [
    {
      href: 'mailto:info@homedefense.it',
      target: '_blank',
      icon: 'far fa-envelope'
    },
    {
      href: 'https://www.instagram.com/homedefense.it',
      target: '_blank',
      icon: 'fab fa-instagram'
    },
    {
      href: 'https://www.twitter.com/homedefenseit',
      target: '_blank',
      icon: 'fab fa-twitter'
    },
    {
      href: 'https://www.facebook.com/homedefense.it/',
      target: '_blank',
      icon: 'fab fa-facebook-f'
    },
    {
      href: 'tel:+393337248456',
      target: '',
      icon: 'fas fa-phone'
    },
    {
      href: 'https://wa.me/+393337248456',
      target: '_blank',
      icon: 'fab fa-whatsapp'
    }
  ]
}
