import React, { useState } from 'react'

import css from './Announcements.module.scss'

const Announcements = React.memo((props) => {
  const [banner, setBanner] = useState(true)

  return (
    banner && (
      <div className={css.Announcements} onClick={() => setBanner(false)}>
        {props.list
          .filter((x) => x.title)
          .sort((a, b) => (a.order < b.order ? 1 : b.order < a.order ? -1 : 0))
          .map((x) => (
            <span key={x.id}>{x.title}</span>
          ))}
      </div>
    )
  )
})

export default Announcements
