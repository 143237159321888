export const reduxHelper = {
  dispatchError
}

function dispatchError(error) {
  if (error?.response !== undefined) {
    console.log(error.response.statusText)
  } else {
    console.log(error.message)
  }
}
