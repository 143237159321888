import React from 'react'
import { connect } from 'react-redux'

import css from './Nav.module.scss'

import { toggleMenu } from '../../../../redux/actions'

const Nav = (props) => (
  <nav className={css.Nav}>
    <div className={css.Hamburger}>
      <button
        className={`hamburger hamburger--elastic ${
          props.ui.menuIsOpen ? 'is-active' : ''
        }`}
        onClick={props.toggleMenu}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </nav>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  toggleMenu
})(Nav)
