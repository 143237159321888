import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { instructorsRef } from '../../database'
import { reduxHelper } from '../../helpers/'
import { onSnapshot } from 'firebase/firestore'

import { updateInstructor, removeInstructor } from '../actions'

export let instructorsSnap

export function* loadInstructorsSaga() {
  try {
    const listener = eventChannel((emit) => {
      instructorsSnap = onSnapshot(instructorsRef, (result) => {
        result.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => instructorsRef.off(listener)
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateInstructor({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateInstructor({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeInstructor({ id, ...data }))
      }
    }
  } catch (error) {
    reduxHelper.dispatchError(error)
  }
}
