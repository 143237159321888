import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { coursesRef, database } from '../../database'
import { reduxHelper, timeHelper } from '../../helpers/'
import {
  onSnapshot,
  where,
  query,
  setDoc,
  addDoc,
  doc
} from 'firebase/firestore'

import { updateCourse, removeCourse } from '../actions'
import { collectionConstants } from '../../constants'

export let coursesSnap

export function* loadCoursesSaga() {
  try {
    const startDate = yield timeHelper.startDayToday()
    const listener = eventChannel((emit) => {
      const q = query(coursesRef, where('start_date', '>=', startDate))
      coursesSnap = onSnapshot(q, (result) => {
        result.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => coursesRef.off(listener)
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateCourse({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateCourse({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeCourse({ id, ...data }))
      }
    }
  } catch (error) {
    reduxHelper.dispatchError(error)
  }
}

export function* saveCourseSaga({ id, payload }) {
  try {
    if (!id) {
      yield addDoc(coursesRef, payload)
    } else {
      yield setDoc(doc(database, collectionConstants.courses, id), payload)
    }
    yield alert('salvato con successo')
  } catch (error) {
    yield reduxHelper.dispatchError(error)
  }
}
