import React from 'react'

import Image from '../../../UI/Image/Image'

import css from './Instructor.module.scss'

const Instructor = ({ instructor }) => (
  <div className={css.InstructorContainer}>
    <Image src={instructor.image} alt={instructor.name} />
    <div className={css.Instructor}>
      <span className={css.Role}>{instructor.role}</span>
      <span className={css.Name}>{instructor.name}</span>
      <span className={css.Description}>{instructor.description}</span>
      <ul className={css.UlInstructor}>
        {instructor.skills.map((s) => (
          <li key={s}>{s}</li>
        ))}
      </ul>
    </div>
  </div>
)

export default Instructor
