import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { feedbacksRef } from '../../database'
import { reduxHelper } from '../../helpers/'
import { onSnapshot } from 'firebase/firestore'

import { updateFeedback, removeFeedback } from '../actions'

export let feedbacksSnap

export function* loadFeedbacksSaga() {
  try {
    const listener = eventChannel((emit) => {
      feedbacksSnap = onSnapshot(feedbacksRef, (result) => {
        result.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => feedbacksRef.off(listener)
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateFeedback({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateFeedback({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeFeedback({ id, ...data }))
      }
    }
  } catch (error) {
    reduxHelper.dispatchError(error)
  }
}
