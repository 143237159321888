import React from 'react'
import { connect } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'

import Image from '../../UI/Image/Image'

import css from './Feedbacks.module.scss'

const Feedbacks = (props) => {
  return (
    <div className={css.Feedbacks}>
      <div className={css.FeedbackTitle}>dicono di noi</div>
      <div className={css.SubTitle}>
        alla fine di ogni corso chiediamo di compilare in forma anonima un
        questionario di valutazione
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={'auto'}
        grabCursor
        loop
        speed={1000}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        modules={[Autoplay]}
      >
        {(props.feedback.list || []).map((f) => (
          <SwiperSlide key={f.id} className={css.SliderFeedback}>
            <Image src={f.image} alt={f.id} />
            <div className={css.Txt}>
              <span className={css.Course}>
                corso: <b>{f.course}</b>
              </span>
              <span className={css.Message}>{f.message}</span>
              <span className={css.Name}>{f.name}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Feedbacks)
