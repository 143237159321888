import React from 'react'
import { Link } from 'react-router-dom'

import css from './Footer.module.scss'

import Social from './Social/Social'

import Logo from '../../../../assets/logo_black.svg'
import Partners from './Partners/Partners'
import Organizations from './Organizations/Organizations'

const Footer = () => (
  <footer className={css.Footer}>
    <Social />
    <div className={css.FooterLogo}>
      <img src={Logo} alt="Home Defense" />
      <div className={css.Txt}>
        © Home Defense {new Date().getFullYear()}. All Rights Reserved.
        <br />
        CF: 03786290134
        <br />
        NCAGE AR088 - UNGM 688556
        <br />
        <Link to="/privacy-policy">Privacy Policy</Link>
        <span>
          v.{' '}
          {document.head.querySelector('meta[name="build-version"]')['content']}
        </span>
      </div>
    </div>
    <Partners />
    <Organizations />
  </footer>
)

export default Footer
