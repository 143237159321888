import { appearanceConstants } from '../../constants'
import { contactConstants } from '../constants'

export const requestContact = (payload) => {
  return {
    type: contactConstants.CONTACT_START,
    payload
  }
}

export const contactSuccess = () => {
  return {
    type: contactConstants.CONTACT_SUCCESS,
    payload: appearanceConstants.messages.contactSuccess
  }
}

export const contactFailure = () => {
  return {
    type: contactConstants.CONTACT_FAILURE,
    payload: appearanceConstants.messages.contactFailure
  }
}

export const contactClear = () => {
  return {
    type: contactConstants.CONTACT_CLEAR
  }
}
