import { announcementConstants } from '../constants'

export const loadAnnouncements = () => {
  return {
    type: announcementConstants.ANNOUNCEMENT_INIT
  }
}

export const updateAnnouncement = (payload) => {
  return {
    type: announcementConstants.UPDATE_ANNOUNCEMENT,
    payload
  }
}

export const removeAnnouncement = (payload) => {
  return {
    type: announcementConstants.REMOVE_ANNOUNCEMENT,
    payload
  }
}
