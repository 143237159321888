import React from 'react'
import { appearanceConstants } from '../../../../../constants'

import css from './Social.module.scss'

const Social = () => (
  <ul className={css.UlSocial}>
    {appearanceConstants.social.map((s) => (
      <li key={s.icon}>
        <a
          href={s.href}
          rel="noopener noreferrer"
          target={s.target}
          className={css.LnkSocial}
        >
          <i className={s.icon}></i>
        </a>
      </li>
    ))}
  </ul>
)
export default Social
