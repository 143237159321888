import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { closeMenu } from '../../../../redux/actions'

import css from './Logo.module.scss'

import HomeDefense from '../../../../assets/logo_red.svg'

const Logo = (props) => (
  <Link to="/" className={css.Logo} onClick={props.closeMenu}>
    <img src={HomeDefense} alt="Home Defense" />
    Home defense
  </Link>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  closeMenu
})(Logo)
