import React from 'react'
import { Outlet } from 'react-router-dom'

import Instructors from '../../UI/Instructors/Instructors'
import CalendarBanner from '../../UI/CalendarBanner/CalendarBanner'
import Feedbacks from '../../UI/Feedbacks/Feedbacks'
import SecurityBanner from '../../UI/SecurityBanner/SecurityBanner'
import Registrations from '../../UI/Registrations/Registrations'

const Courses = () => (
  <>
    <Outlet />
    <Registrations />
    <Instructors />
    <SecurityBanner />
    <Feedbacks />
    <CalendarBanner />
  </>
)

export default Courses
