import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { contactsRef } from '../../database'
import { addDoc } from 'firebase/firestore'
import { contactSuccess, contactFailure, contactClear } from '../actions'

export function* addContactSaga(payload) {
  try {
    yield addDoc(contactsRef, payload)
    yield put(contactSuccess())
    yield clearMessage()
  } catch (error) {
    yield put(contactFailure())
    yield clearMessage()
  }
}

function* clearMessage() {
  const listener = eventChannel((emit) => {
    const timer = setTimeout(() => {
      emit(true)
    }, 5000)
    return () => clearTimeout(timer)
  })
  while (true) {
    yield take(listener)
    yield put(contactClear())
  }
}
