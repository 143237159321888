import { uiConstants } from '../constants'

export const toggleMenu = () => {
  return {
    type: uiConstants.TOGGLE_MENU
  }
}

export const closeMenu = () => {
  return {
    type: uiConstants.CLOSE_MENU
  }
}
