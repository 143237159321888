import React from 'react'
import { Link } from 'react-router-dom'

import css from './CalendarBanner.module.scss'

const CalendarBanner = () => (
  <Link to="/calendar" className={css.Banner}>
    <div className={css.InsideBanner}>
      <div className={css.In}>
        Consulta il calendario per scoprire le prossime date disponibili
      </div>
    </div>
  </Link>
)

export default CalendarBanner
