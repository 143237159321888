import { authConstants } from '../constants'

const initialState = {
  identity: null,
  error: null,
  loading: false,
  signout: false
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.AUTH_LOGIN_GOOGLE:
      return { ...state, loading: true, error: null, identity: null }
    case authConstants.AUTH_LOGIN_SUCCESS:
      return { ...state, loading: false, error: null, identity: action.payload }
    case authConstants.AUTH_LOGIN_FAILURE:
      return { ...state, error: action.payload, loading: false, identity: null }
    case authConstants.AUTH_LOGOUT:
      return { ...state, loading: true, error: null, identity: null }
    case authConstants.AUTH_LOGOUT_SUCCESS:
      return { ...state, loading: false, identity: null }
    default:
      return state
  }
}
