import React from 'react'

import css from './SecurityBanner.module.scss'

const SecurityBanner = React.memo(() => (
  <div className={css.Banner}>
    <div className={css.InsideBanner}>
      <div className={css.In}>
        <div className={css.Title}>Sicurezza</div>
        Prima di ogni sessione, indipendentemente dal livello che tu abbia,
        viene fatto un briefing iniziale ricordando le regole base di sicurezza
        e le regole complementari.
        <br />
        Nel caso venga riscontrata una mancanza di attenzione, l'allievo potrà
        essere allontanato temporaneamente o integralmente dal corso.
      </div>
    </div>
  </div>
))

export default SecurityBanner
