import { instructorConstants } from '../constants'

export const loadInstructors = () => {
  return {
    type: instructorConstants.INSTRUCTOR_INIT
  }
}

export const updateInstructor = (payload) => {
  return {
    type: instructorConstants.UPDATE_INSTRUCTOR,
    payload
  }
}

export const removeInstructor = (payload) => {
  return {
    type: instructorConstants.REMOVE_INSTRUCTOR,
    payload
  }
}
