import { feedbackConstants } from '../constants'

export const loadFeedbacks = () => {
  return {
    type: feedbackConstants.FEEDBACK_INIT
  }
}

export const updateFeedback = (payload) => {
  return {
    type: feedbackConstants.UPDATE_FEEDBACK,
    payload
  }
}

export const removeFeedback = (payload) => {
  return {
    type: feedbackConstants.REMOVE_FEEDBACK,
    payload
  }
}
