import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

var firebaseConfig = {
  apiKey: 'AIzaSyCznR-mN_GzJ627CojkMpDzfteKT1lY2sc',
  authDomain: 'homedefense-63b23.firebaseapp.com',
  databaseURL: 'https://homedefense-63b23.firebaseio.com',
  projectId: 'homedefense-63b23',
  storageBucket: 'homedefense-63b23.appspot.com',
  messagingSenderId: '292636607535',
  appId: '1:292636607535:web:3dfcc9a8bfd0f5ca0932da',
  measurementId: 'G-JM4XBHJT2Q'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
