import { db, storage } from './firestore'
import { collection } from 'firebase/firestore'
import { ref } from 'firebase/storage'
import { collectionConstants } from './constants'

export const database = db

export const instructorsRef = collection(db, collectionConstants.instructors)
export const coursesRef = collection(db, collectionConstants.courses)
export const contactsRef = collection(db, collectionConstants.contacts)
export const feedbacksRef = collection(db, collectionConstants.feedbacks)
export const announcementsRef = collection(
  db,
  collectionConstants.announcements
)

export const storageRef = ref(storage)
