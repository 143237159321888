import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth } from '../../firestore'
import {
  inMemoryPersistence,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup
} from 'firebase/auth'
import { loginFailure, loginSuccess } from '../actions'

export function* loginGoogleSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, inMemoryPersistence).then(() => {
        const provider = new GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/userinfo.email')
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
        return signInWithPopup(auth, provider)
          .then((result) => {
            emit(result)
          })
          .catch((error) => {
            emit(error)
          })
      })
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(error.message))
  }
}
