import { courseConstants } from '../constants'

export const loadCourses = () => {
  return {
    type: courseConstants.COURSE_INIT
  }
}

export const updateCourse = (payload) => {
  return {
    type: courseConstants.UPDATE_COURSE,
    payload
  }
}

export const removeCourse = (payload) => {
  return {
    type: courseConstants.REMOVE_COURSE,
    payload
  }
}

export const saveCourse = (id, payload) => {
  return {
    type: courseConstants.COURSE_SAVE,
    id,
    payload
  }
}
