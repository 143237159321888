import React from 'react'

import css from './Partners.module.scss'

import Defendo from '../../../../../assets/partners/defendo.svg'
import Casabella from '../../../../../assets/partners/casabella.svg'
import KillHouse from '../../../../../assets/partners/killhouse.svg'
import TactiClub from '../../../../../assets/partners/tacticlub.svg'
import GirlsDefense from '../../../../../assets/partners/girlsdefense.svg'

const Partners = () => (
  <div className={css.Partners}>
    <a
      href="https://defendokravmaga.it"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={Defendo} alt="Defendo Krav Maga" />
    </a>
    <a
      href="https://armeriacasabella.it/"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={Casabella} alt="Armeria Casabella" />
    </a>
    <a
      href="https://killhouse.it"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={KillHouse} alt="Kill House" />
    </a>
    <a
      href="https://girlsdefense.it"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={GirlsDefense} alt="GirlsDefense" />
    </a>
    <a
      href="https://tacti.club"
      rel="noopener noreferrer"
      target="_blank"
      className={css.LnkPartner}
    >
      <img src={TactiClub} alt="TactiClub" />
    </a>
  </div>
)

export default Partners
