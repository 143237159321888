import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { appearanceConstants } from '../../../../constants'

import { toggleMenu } from '../../../../redux/actions'

import css from './Menu.module.scss'

const Menu = (props) => {
  const menuClickHandler = () => {
    props.toggleMenu()
  }
  return (
    <div className={`${css.Menu} ${props.ui.menuIsOpen ? css.MenuIsOpen : ''}`}>
      <div className={css.HomeDefense}>Home Defense</div>
      <ul className={css.UlMenu}>
        {appearanceConstants.menu.map((m) => (
          <li key={m.to}>
            <Link to={m.to} className={css.LnkMenu} onClick={menuClickHandler}>
              {m.label} <span>{m.description}</span>
            </Link>
          </li>
        ))}
      </ul>
      <ul className={css.UlSocial}>
        {appearanceConstants.social.map((s) => (
          <li key={s.icon}>
            <a href={s.href} rel="noopener noreferrer" target={s.target}>
              <i className={s.icon}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  toggleMenu
})(Menu)
