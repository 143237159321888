import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from './redux/store'

import Layout from './components/Containers/Layout/Layout'
import PageLoader from './components/UI/PageLoader/PageLoader'
import Courses from './components/Containers/Courses/Courses'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const HomeDefenseLazy = lazy(() =>
  import('./components/Pages/HomeDefense/HomeDefense')
)
const CombatShootingCivilLazy = lazy(() =>
  import('./components/Pages/CombatShootingCivil/CombatShootingCivil')
)
const CombatShootingSecurityLazy = lazy(() =>
  import('./components/Pages/CombatShootingSecurity/CombatShootingSecurity')
)
const CombatShootingPoliceLazy = lazy(() =>
  import('./components/Pages/CombatShootingPolice/CombatShootingPolice')
)
const PartnersLazy = lazy(() => import('./components/Pages/Partners/Partners'))
const CalendarLazy = lazy(() => import('./components/Pages/Calendar/Calendar'))
const AdminLazy = lazy(() => import('./components/Pages/Admin/Admin'))
const SpecialEventsCoursesLazy = lazy(() =>
  import('./components/Pages/SpecialEventsCourses/SpecialEventsCourses')
)
const PrivacyPolicyLazy = lazy(() =>
  import('./components/Pages/PrivacyPolicy/PrivacyPolicy')
)
const SafeGuardingLazy = lazy(() =>
  import('./components/Pages/SafeGuarding/SafeGuarding')
)
const SimulationLazy = lazy(() =>
  import('./components/Pages/Simulation/Simulation')
)

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />
              <Route path="/partners" element={<PartnersLazy />} />
              <Route path="/calendar" element={<CalendarLazy />} />
              <Route path="/admin" element={<AdminLazy />} />
              <Route path="/admin/:id" element={<AdminLazy />} />
              <Route element={<Courses />}>
                <Route path="/home-defense" element={<HomeDefenseLazy />} />
                <Route
                  path="/combat-shooting-civil"
                  element={<CombatShootingCivilLazy />}
                />
                <Route
                  path="/combat-shooting-security"
                  element={<CombatShootingSecurityLazy />}
                />
                <Route
                  path="/combat-shooting-police"
                  element={<CombatShootingPoliceLazy />}
                />
                <Route
                  path="/special-events-and-courses"
                  element={<SpecialEventsCoursesLazy />}
                />
                <Route path="/simulation" element={<SimulationLazy />} />
              </Route>
              <Route path="/privacy-policy" element={<PrivacyPolicyLazy />} />
              <Route path="/safe-guarding" element={<SafeGuardingLazy />} />
              <Route path="*" element={<HomeLazy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
