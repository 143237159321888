import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'

import css from './Slideshow.module.scss'

const images = require.context('../../../assets/slideshow', true)

const Slideshow = () => (
  <div className={css.SliderContainer}>
    <Swiper
      spaceBetween={0}
      slidesPerView={'auto'}
      grabCursor
      loop
      speed={5000}
      autoplay={{
        delay: 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
      modules={[Autoplay]}
    >
      {Array.from(Array(30).keys())
        .sort(() => (Math.random() > 0.5 ? 1 : -1))
        .map((i) => (
          <SwiperSlide key={i} className={css.SliderPhoto}>
            <img src={images(`./${i + 1}.jpg`)} alt={i} />
          </SwiperSlide>
        ))}
    </Swiper>
  </div>
)

export default Slideshow
