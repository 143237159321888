import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import {
  watchAnnouncement,
  watchAuth,
  watchContact,
  watchCourse,
  watchFeedback,
  watchInstructor
} from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production'
})

sagaMiddleware.run(watchAnnouncement)
sagaMiddleware.run(watchCourse)
sagaMiddleware.run(watchInstructor)
sagaMiddleware.run(watchFeedback)
sagaMiddleware.run(watchContact)
sagaMiddleware.run(watchAuth)
