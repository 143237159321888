import { combineReducers } from 'redux'

import ui from './ui.reducer'
import course from './course.reducer'
import instructor from './instructor.reducer'
import contact from './contact.reducer'
import feedback from './feedback.reducer'
import auth from './auth.reducer'
import announcement from './announcement.reducer'
import redirect from './redirect.reducer'

const rootReducer = combineReducers({
  ui,
  course,
  instructor,
  contact,
  feedback,
  auth,
  announcement,
  redirect
})

export default rootReducer
